import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'

// In the reducer, define our initial state (of the entire application) [t=24m30s]
const initialState = {
    token: null,
    error: null,
    loading: false
}

// The reducer's job is to take the initial state and action to return the state that is updated
const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        error: null,
        loading: false
    })
}

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
}

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
    })
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:   return authStart(state, action)
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
        case actionTypes.AUTH_FAIL:    return authFail(state, action)
        case actionTypes.AUTH_LOGOUT:  return authLogout(state, action)
        default:                       return state
    }
}

export default reducer;